import { A } from "@solidjs/router";
import { onMount } from "solid-js";
import { useAppState } from "~/AppContext";

export default function Auth() {
  const { t } = useAppState();
  onMount(() => {
    console.log("onMount auth");
    window?.opener?.postMessage({
      type: "alreadyLogined",
    });
  });
  return (
    <main class="text-center mx-auto p-4 py-12">
      <A
        href="/"
        class="select-none text-white bg-neutral-700 hover:bg-neutral-800 focus:ring-4 focus:ring-neutral-300 font-medium rounded text-sm px-5 py-2.5 dark:bg-neutral-600 dark:hover:bg-neutral-700 focus:outline-none dark:focus:ring-neutral-800"
      >
        {t("home")}
      </A>
    </main>
  );
}
